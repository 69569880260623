.full-scr {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transition: all .3s;
  z-index: -1;
  background-color: #e43624; }

.scroll-menu {
  transition: all .3s;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  z-index: 3;
  a {
    margin: 2rem 0; }
  .block {
    display: inline-block;
    margin: 0; }
  h2 {
    font-size: 6.6vh;
    // text-transform: uppercase
    margin: 0;
    pointer-events: auto;
    transition: transform .66s;
    color: #fff;
    line-height: 1;
    margin: 0 3vh;
    // transform: perspective(360px) rotateX(12deg) rotateY(0deg) scale3d(1, 1, 1)
    // &:hover
    //   transform: perspective(360px) rotateX(60deg) rotateY(0deg) scale3d(1, 1.33, 1)
    // &::after
    //   content: ''
    //   display: inline-block
    //   background-image: url('../images/trefoil.svg')
    //   background-size: 6vh 5vh
    //   background-repeat: no-repeat
    //   width: 6vh
    //   height: 6vh
    //   margin: 0 4vh
 }    //   z-index: 1

  .block:last-of-type h2::after {
      display: none; }
  .container {
    height: auto;
    min-height: auto;
    min-width: auto;
    white-space: nowrap;
    position: relative;
    &:hover * {
      opacity: .9;
      transition: opacity .3s; } } }

// @media screen and (max-width : 1024px)
//   .scroll-menu
//       transform: perspective(360px) rotateX(0) rotateY(15deg) scale3d(1, 1, 1)

.scroll-right {
  animation-name: right;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate; }

.scroll-left {
  animation-name: left;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate; }

.peek-bg {
  background: #ffffff; }

@keyframes left {
  0% {
    margin-left: 0; }
  100% {
    margin-left: -50%; } }
@keyframes right {
  from {
    margin-left: -50%; }
  to {
    margin-left: 0; } }

@keyframes words-left {
  0% {
    padding-left: 10rem; }
  100% {
    padding-left: 0rem; } }

@keyframes rotate-clockwise {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0); }

  20%, 80% {
    transform: translate3d(2px, 0, 0); }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0); }

  40%, 60% {
    transform: translate3d(4px, 0, 0); } }
