@mixin fontFace($family, $src, $style: normal, $weight: normal) {
  @font-face {
    font-family: $family;
    src: url("#{$src}.eot");
    src: url("#{$src}.eot?#iefix") format("embedded-opentype"), url("#{$src}.woff") format("woff"), url("#{$src}.ttf") format("truetype"), url("#{$src}.svg##{$family}") format("svg");
    font-style: $style;
    font-weight: $weight; } }

@include fontFace('NH-55','../fonts/NHaasGroteskTXPro-55Rg');
@include fontFace('NH-65','../fonts/NHaasGroteskTXPro-65Md');

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -moz-font-feature-settings: "liga" on;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }

html {
  font-size: 62.5%; }

body {
  font-family: 'NH-55', -apple-system, BlinkMacSystemFont, system, -apple-system, ".SFNSText-Regular", "San Francisco", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-size: 1.6rem;
  line-height: 1.33;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background-color: #e43624;
  color: #fff; }

a {
  color: inherit;
  text-decoration: none; }

.wrapper {
  text-align: left;
  margin: 3.5rem 4rem;
  color: #fff;
  letter-spacing: -.1px;
  line-height: 1.33;
  height: calc(100vh - 7rem);
  position: relative;

  > * {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    // margin: calc(2rem / 2 * -1)

    > {
      * {
        // margin: calc(2rem / 2)
        // margin: 2rem
 }        // flex-grow: 1

      // \:last-child
      //   flex-basis: 0
      //   flex-grow: 999
 } } }      //   min-width: calc(50% - 2rem)

.content {
  width: 100%;
  flex: 1;
  .content-head {
    max-width: 1280px;
    width: 90%;
    margin-bottom: 3rem; }

  h2 {
    font-size: 2rem;
    font-family: "NH-65";
    font-weight: normal;
    margin: 0 0 2rem 0; }
  h3 {
    font-size: 1.6rem;
    font-weight: normal;
    margin: 0; }

  .framewrapper {
    &::before {
      content: '';
      background-image: url(../images/browser-header.svg);
      background-repeat: no-repeat;
      height: 30px;
      max-width: 1440px;
      width: 90%;
      display: block;
      position: relative;
      z-index: 9;
      left: 0;
      top: 0;
      border-radius: 5px 5px 0 0;
      background-color: #282828;
      overflow: hidden;
      transition: all .2s; }
    .frame {
      display: block;
      max-width: 1440px;
      width: 90%;
      height: calc(90vh - 22rem);
      overflow-x: auto;
      box-shadow: -1px 12px 12px 0 rgba(0,0,0,0.15);
      background-color: #fff;
      border-radius: 0 0 5px 5px;
      scroll-behavior: smooth;
      img {
        width: 100%;
        margin: 0;
        padding: 0;
        &:hover {
          cursor: ns-resize; } } }
    &.full-screen {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      max-width: 100vw;
      max-height: 100vh;
      margin: 0;
      padding: 0;
      &::before {
        display: none; }
      .frame {
        width: 100vw;
        height: 100vh;
        max-width: 100vw;
        max-height: 100vh;
        img:hover {
          cursor: pointer; } } }

    .videoframe {
      display: block;
      max-width: 1440px;
      width: 90%;
      max-height: calc(90vh - 22rem);
      overflow: hidden;
      box-shadow: -1px 12px 12px 0 rgba(0,0,0,0.15);
      background-color: #fff;
      border-radius: 0 0 5px 5px;
      .player-wrapper {
        margin: 0;
        position: relative;
        padding-top: 53.465%;
        .player {
          position: absolute;
          top: 0;
          left: 0; } } } }
  .options-grid {
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    max-height: calc(90vh - 22rem);
    .img-grid-wrap {
      display: flex;
      flex-direction: column;
      span {
        margin-top: 1.5rem; }
      img {
        max-width: 30vw; } }
    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      img {
        max-width: 50vw; } } } }


.lightbox-wrapper {
  .img-open {
    transition: transform .3s;
    &:hover {
      transform: scale(1.01); } }
  .lightbox {
    border: none;
    img {
      width: 80vw;
      height: auto;
      max-width: 80vw !important;
      max-height: 80vh;
      object-fit: contain; } } }

.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #fff;
  margin-right: 4rem;
  @media (max-width: 900px) {
    height: auto;
    width: 100%;
    margin: 0 0 3rem 0; }
  h1 {
    font-size: 2rem;
    font-family: "NH-65";
    font-weight: normal;
    margin: 0 0 2rem 0; }
  hr {
    padding: 0;
    margin: 2rem 0;
    border: none;
    @media (max-width: 900px) {
      display: none; } }
  ol.links {
    span:not(:first-child) {
      display: block;
      margin-top: 2rem; }
    li {
      padding: 0;
      margin: 3px 0;
      &:before {
        content: "";
        width: 2px;
        display: inline-block; }
      .active {
        font-family: "NH-65"; }
      a {
        text-decoration: none;
        color: #fff; }
      @media (max-width: 900px) {
        float: left;
        margin-right: 10px; } } }
  .logo {
    max-width: 100px;
    width: 8rem;
    @media (max-width: 900px) {
      position: fixed;
      top: 3.5rem;
      right: 4rem; } } }

.Collapsible__trigger {
  display: block;
  position: relative;
  &:hover {
    cursor: pointer; }
  &.is-open:after {
    transform: rotateZ(180deg); }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: .8rem;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid #fff;
    display: block;
    transition: transform 300ms; } }
